
export default function FormatBulletList(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1.5rem"
      width="1.5rem"
      {...props}
    >
      <path d="M7 5h14v2H7V5m0 8v-2h14v2H7M4 4.5A1.5 1.5 0 015.5 6 1.5 1.5 0 014 7.5 1.5 1.5 0 012.5 6 1.5 1.5 0 014 4.5m0 6A1.5 1.5 0 015.5 12 1.5 1.5 0 014 13.5 1.5 1.5 0 012.5 12 1.5 1.5 0 014 10.5M7 19v-2h14v2H7m-3-2.5A1.5 1.5 0 015.5 18 1.5 1.5 0 014 19.5 1.5 1.5 0 012.5 18 1.5 1.5 0 014 16.5z" />
    </svg>
  );
}

