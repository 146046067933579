
export default function FormatRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      height="1.5rem"
      width="1.5rem"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20 5a1 1 0 110 2H4a1 1 0 010-2h16zM20 9a1 1 0 110 2h-8a1 1 0 110-2h8zM21 14a1 1 0 00-1-1H4a1 1 0 100 2h16a1 1 0 001-1zM20 17a1 1 0 110 2h-8a1 1 0 110-2h8z"
      />
    </svg>
  );
}