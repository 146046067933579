import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  TextFormatType,
  FORMAT_ELEMENT_COMMAND,
  ElementFormatType,
  RangeSelection,
  EditorState
} from 'lexical';
import {
  $isParentElementRTL,
  $wrapNodes,
  $isAtNodeEnd
} from "@lexical/selection";
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode
} from "@lexical/rich-text";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ListItemNode, ListNode } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode
} from "@lexical/list";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import AutoLinkPlugin from './auto-link-plugin';
import { createPortal } from 'react-dom';

import styles from '../../styles/components/rich-text.module.scss';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import EditFilledSVG from '~/assets/svg/icons/edit-filled';
import FormatH1 from '~/assets/svg/icons/format-h1';
import FormatH2 from '~/assets/svg/icons/format-h2';
import FormatParagraph from '~/assets/svg/icons/format-paragraph';
import FormatBulletList from '~/assets/svg/icons/format-bullet-list';
import FormatNumberList from '~/assets/svg/icons/format-number-list';
import FormatQuote from '~/assets/svg/icons/format-quote';
import FormatBold from '~/assets/svg/icons/format-bold';
import FormatItalic from '~/assets/svg/icons/format-italic';
import FormatUnderline from '~/assets/svg/icons/format-underline';
import FormatLink from '~/assets/svg/icons/format-link';
import FormatLeft from '~/assets/svg/icons/format-left';
import FormatCenter from '~/assets/svg/icons/format-center';
import FormatRight from '~/assets/svg/icons/format-right';
import classNames from 'classnames';
import FormatH3 from '~/assets/svg/icons/format-h3';
import Button from '../button';
import { useGlobalState } from '~/state';
import { ActionType } from '~/state/types';
import { plainTextToLexicalState } from '~/lib/helpers/lexical';

const LowPriority = 1;
type Block = 'h1' | 'h2' | 'h3' | 'paragraph' | 'ol' | 'ul' | 'quote';
type Heading = 'h1' | 'h2' | 'h3';

const blockTypeToBlockName = {
  paragraph: "Normal",
  h1: "Large Heading",
  h2: "Small Heading",
  ul: "Bulleted List",
  ol: "Numbered List",
  quote: "Quote"
};

function getSelectedNode(selection: RangeSelection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

function positionEditorElement(editor: any, rect: any) {
  if (rect === null) {
    editor.style.opacity = "0";
    editor.style.top = "-1000px";
    editor.style.left = "-1000px";
  } else {
    editor.style.opacity = "1";
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
    editor.style.left = `${rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
      }px`;
  }
}

function FloatingLinkEditor({ editor }) {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl("");
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== "link-input") {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl("");
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      )
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  return (
    <div ref={editorRef} className={styles["link-editor"]}>
      {isEditMode ? (
        <input
          ref={inputRef}
          className={styles["link-input"]}
          value={linkUrl}
          onChange={(event) => {
            setLinkUrl(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              if (lastSelection !== null) {
                if (linkUrl !== "") {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                }
                setEditMode(false);
              }
            } else if (event.key === "Escape") {
              event.preventDefault();
              setEditMode(false);
            }
          }}
        />
      ) : (
        <div className={styles["link-input"]}>
          <a href={linkUrl} target="_blank" rel="noopener noreferrer">
            {linkUrl}
          </a>
          <div
            className={styles["link-edit"]}
            role="button"
            tabIndex={0}
            onMouseDown={(event) => event.preventDefault()}
            onClick={() => {
              setEditMode(true);
            }}
          >
            <div className={styles['edit-icon']}>
              <EditFilledSVG />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

interface ToolbarProps {
  handleSave?: (_stringifiedEditorState: string) => Promise<void>;
  initialEditorState?: string;
}
function Toolbar(props: ToolbarProps) {

  // for showing the edit icon
  const { state } = useGlobalState();
  const isAdmin = state?.user?.data?.isAdmin;

  const [editor] = useLexicalComposerContext();
  const [blockType, setBlockType] = useState<Block>("paragraph");
  const [isBold, setIsBold] = useState<boolean>(false);
  const [isItalic, setIsItalic] = useState<boolean>(false);
  const [isUnderline, setIsUnderline] = useState<boolean>(false);
  const [isLink, setIsLink] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [lastSave, setLastSave] = useState<string>(props.initialEditorState || '');
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [shouldSave, setShouldSave] = useState<boolean>(false);

  const { dispatch } = useGlobalState();

  const saveEditorState = async () => {
    const stringifiedEditorState = JSON.stringify(editor.getEditorState());
    if (Boolean(props.handleSave)) {
      setSaving(true);
      try {
        await props.handleSave(stringifiedEditorState);
        setLastSave(stringifiedEditorState);
        setShouldSave(false);
      } catch (e: any) {
        dispatch({
          type: ActionType.ADD_MODAL_DATA,
          payload: {
            title: `Something went wrong`,
            message: `This is the error message: ${e.message}`
          }
        });
      }
      setSaving(false);
    }
  };

  const formatList = (listType: Block) => {
    if (listType === "ol" && blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      setBlockType("ol");
    } else if (listType === "ul" && blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      setBlockType("ul");
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setBlockType("paragraph");
    }
  };

  const formatHeading = (type: Heading) => {
    if (blockType !== type) {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode(type));
        }
      });
    }
  };

  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
  };

  const formatText = (type: TextFormatType) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, type);
  };

  const formatElement = (align: ElementFormatType) => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, align);
  };

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type as Block);
        }
      }
      // Update text format 
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  function handleEditMode() {
    if (isEditable && shouldSave) {
      dispatch({
        type: ActionType.ADD_MODAL_DATA,
        payload: {
          title: `Save changes?`,
          message: `You have unsaved changes. Would you like to save them?`,
          component: (
            <Button
              text='Save'
              onPress={() => {
                saveEditorState();
                dispatch({ type: ActionType.FLUSH_MODAL_DATA });
              }}
            />
          )
        }
      });
    } else {
      editor.setEditable(!isEditable);
    }
  }

  function onChange(editorState: EditorState) {
    setShouldSave(JSON.stringify(editorState) !== lastSave);
  }

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    const removeEditableListener = editor.registerEditableListener(
      (_isEditable) => {
        setIsEditable(_isEditable);
      },
    );

    return () => {
      removeEditableListener();
    };
  }, [editor]);

  useEffect(() => {
    // if the user is about to leave the page, we want to prompt them to save
    const beforeunload = (e: BeforeUnloadEvent) => {
      // prompt the user to save
      if (isEditable) {
        e.preventDefault();
        e.returnValue = '';

        return '';
      }
    };
    window.addEventListener('beforeunload', beforeunload);
    return () => {
      window.removeEventListener('beforeunload', beforeunload);
    };
  }, [isEditable]);

  if (!isEditable) {
    if (!isAdmin) return null;
    return (
      <div className={styles['edit-icon-container']} onClick={() => handleEditMode()}>
        <div className={classNames(styles['edit-icon'], styles['edit-icon-light'])}>
          <EditFilledSVG />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles['editor-toolbar']}>
        <div className={classNames(styles['toolbar-icon'], { [styles['toolbar-icon-active']]: blockType === 'h1' })} onClick={() => formatHeading('h1')}><FormatH1 /></div>
        <div className={classNames(styles['toolbar-icon'], { [styles['toolbar-icon-active']]: blockType === 'h2' })} onClick={() => formatHeading('h2')}><FormatH2 /></div>
        <div className={classNames(styles['toolbar-icon'], { [styles['toolbar-icon-active']]: blockType === 'h3' })} onClick={() => formatHeading('h3')}><FormatH3 /></div>
        <div className={classNames(styles['toolbar-icon'], { [styles['toolbar-icon-active']]: blockType === 'paragraph' })} onClick={() => formatParagraph()}><FormatParagraph /></div>
        <div className={classNames(styles['toolbar-icon'], { [styles['toolbar-icon-active']]: blockType === 'ul' })} onClick={() => formatList('ul')}><FormatBulletList /></div>
        <div className={classNames(styles['toolbar-icon'], { [styles['toolbar-icon-active']]: blockType === 'ol' })} onClick={() => formatList('ol')}><FormatNumberList /></div>
        <div className={classNames(styles['toolbar-icon'], { [styles['toolbar-icon-active']]: blockType === 'quote' })} onClick={() => formatQuote()}><FormatQuote /></div>
        <div className={classNames(styles['toolbar-icon'], { [styles['toolbar-icon-active']]: isBold })} onClick={() => formatText('bold')}><FormatBold /></div>
        <div className={classNames(styles['toolbar-icon'], { [styles['toolbar-icon-active']]: isItalic })} onClick={() => formatText('italic')}><FormatItalic /></div>
        <div className={classNames(styles['toolbar-icon'], { [styles['toolbar-icon-active']]: isUnderline })} onClick={() => formatText('underline')}><FormatUnderline /></div>
        <div className={classNames(styles['toolbar-icon'], { [styles['toolbar-icon-active']]: isLink })} onClick={() => insertLink()}><FormatLink /></div>
        {isLink && createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
        <div className={classNames(styles['toolbar-icon'], { [styles['toolbar-icon-active']]: false })} onClick={() => formatElement('left')}><FormatLeft /></div>
        <div className={classNames(styles['toolbar-icon'], { [styles['toolbar-icon-active']]: false })} onClick={() => formatElement('center')}><FormatCenter /></div>
        <div className={classNames(styles['toolbar-icon'], { [styles['toolbar-icon-active']]: false })} onClick={() => formatElement('right')}><FormatRight /></div>
        <div className={styles['save-button']}><Button onPress={saveEditorState} loading={saving} disabled={!shouldSave} text='Save' size='xtra-small' /></div>
        <div className={styles['save-button']}><Button style='secondary' onPress={() => handleEditMode()} text='Done Editing' size='xtra-small' /></div>
      </div>

      <OnChangePlugin onChange={onChange} />
    </>
  );
}

interface RichTextProps {
  handleSave?: (_stringifiedEditorState: string) => Promise<void>;
  initialEditorState?: string;
  editable?: boolean;
  initialText?: string; // init the editor with plain text
}
export default function RichText(props: RichTextProps) {

  const [parsedInitialEditorState, setParsedInitialEditorState] = useState<string>(null!);

  const initialEditorState = useMemo(() => props.initialEditorState || parsedInitialEditorState, [props.initialEditorState, parsedInitialEditorState]);

  const SHOULD_WAIT_FOR_EDITOR_STATE = useMemo(() => props.initialText && !initialEditorState, [props.initialText, initialEditorState]);

  // for showing the placeholder text
  const { state } = useGlobalState();
  const isAdmin = state?.user?.data?.isAdmin;



  function onError(error) {
    console.error(error);
  }


  useEffect(() => {

    if (!props.initialText || props.initialEditorState) {
      setParsedInitialEditorState(null!);
      return;
    }

    const convertToEditorState = async () => {
      const _initialEditorState = await plainTextToLexicalState(props.initialText);
      setParsedInitialEditorState(_initialEditorState);

    };
    convertToEditorState();
  }, [props.initialText, props.initialEditorState]);

  const theme = {
    // Theme styling goes here
    ltr: styles['ltr'],
    rtl: styles['rtl'],
    placeholder: styles['editor-placeholder'],
    paragraph: styles['editor-paragraph'],
    list: {
      ol: styles['editor-list-ol'],
      ul: styles['editor-list-ul'],
      listitem: styles['editor-listitem'],
      nested: {
        listitem: styles['nested-listitem']
      },
    },
    text: {
      bold: styles['editor-text-bold'],
      italic: styles['editor-text-italic'],
      underline: styles['editor-text-underline'],
    },
    link: styles['editor-link'],
    heading: {
      h1: styles['editor-heading-h1'],
      h2: styles['editor-heading-h2'],
      h3: styles['editor-heading-h3'],
    },
    quote: styles['editor-quote'],
  };


  const initialConfig = {
    namespace: 'MyEditor',
    theme,
    onError,
    nodes: [ListNode, ListItemNode, HeadingNode, QuoteNode, AutoLinkNode, LinkNode],
    ...(initialEditorState && { editorState: initialEditorState }),
    editable: false
  };


  function renderPlaceholder() {

    if (!isAdmin) return null;

    return (
      <div className={styles['editor-placeholder']}>
        {`Start typing... click the edit icon enable`}
      </div>
    );
  }

  if (SHOULD_WAIT_FOR_EDITOR_STATE) return null;

  return (
    <div className={styles['composer-container']}>
      <LexicalComposer initialConfig={initialConfig}>
        {props.editable && <Toolbar
          handleSave={props.handleSave}
          initialEditorState={initialEditorState}
        />}

        <div className={styles['editor-container']}>
          <RichTextPlugin
            contentEditable={<ContentEditable className={styles['editor-input']} />}
            placeholder={renderPlaceholder()}
            ErrorBoundary={LexicalErrorBoundary}
          />

          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
        </div>
      </LexicalComposer>
    </div>
  );
}