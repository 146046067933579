import { FullTitlePageComponent, PageComponent, Title } from 'types';
import Firebase from '@/clients/firebase/firebase-react-query';
import { Box } from '@/components/layout/box';
import FullWidthTitle from '@/components/tiles/full-width-title';


export default function FullTitle(props: PageComponent<FullTitlePageComponent>) {

  const { data: title } = Firebase<Title>().fetchCollectionDocByPath(
    props.data.titleId,
    'titles',
  );

  if (!title) return null;

  return (
    <Box>
      <FullWidthTitle
        title={title}
      />
    </Box>
  );
}