'use client';

import { truncate } from 'sdk';
import Text from '@/components/typography/text';
import styles from '@/styles/components/tiles/full-width-title.module.scss';
import { useCallback, useState } from 'react';
import { Title } from 'types';

interface SummaryProps {
  title: Title;
}

export default function Summary({ title }: SummaryProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const truncatedSummary = useCallback(() => {
    return isExpanded ? title.summary : truncate(title.summary, 250);
  }, [title.summary, isExpanded]);

  const moreToRead = useCallback(() => {
    return truncatedSummary?.length < title.summary?.length;
  }, [truncatedSummary, title.summary]);

  function handleReadMore() {
    setIsExpanded(!isExpanded);
  }

  return (
    <Text copy>
      <Text as='span' copy>
        {truncatedSummary()}
      </Text>
      {moreToRead() && <Text onClick={handleReadMore} color='primary' as='span' className={styles.readMore}> Read {isExpanded ? 'less' : 'more'}</Text>}
    </Text>
  );
}

