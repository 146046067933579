import {
  CalloutPageComponent,
  CollectionPageComponent,
  BookstoresPageComponent,
  PageComponent,
  TagsPageComponent,
  PageComponentData,
  TextPageComponent,
  TypesenseSearchProps,
  AlgoliaOptions,
  ImagePageComponent,
  FullTitlePageComponent,
} from 'types';

import PageComponentCallout from './callout';
import Collection from './collection';
import Tags from './tags';
import Bookstores from './bookstores';

import styles from '../../styles/components/page-components/index.module.scss';
import { useGlobalState } from '../../state';
import classNames from 'classnames';
import TextContent from './text-content';
import { UserProfile } from 'firebase/auth';
import { algoliaToTypesenseOptions } from 'sdk';
import { Typesense } from 'sdk/src/clients/typesense/react-query';
import ImageComponent from './image';
import FullTitle from './full-title';

interface PageComponentManagerProps {
  components: PageComponent<PageComponentData>[];
}

function CustomBookstoreHandler({ component }: { component: PageComponent<PageComponentData>; }) {
  const IS_CUSTOM_QUERY = Boolean((component as PageComponent<BookstoresPageComponent>).data?.customOptions);
  let typesenseOptions;
  let customSellers;

  if (IS_CUSTOM_QUERY) {
    const customOptionsString = (component as PageComponent<BookstoresPageComponent>).data?.customOptions;
    const unknownOptions = customOptionsString ? JSON.parse(customOptionsString) : {};
    if (unknownOptions?.params) {
      // these are typesense options 
      typesenseOptions = unknownOptions as TypesenseSearchProps;
    } else {
      typesenseOptions = algoliaToTypesenseOptions({
        algoliaOptions: {
          index: 'users',
          options: unknownOptions as AlgoliaOptions['options']
        }
      });
    }

    customSellers = Typesense<UserProfile>().search({
      props: typesenseOptions,
      reactQueryOptions: {
        enabled: Boolean(typesenseOptions?.index)
      }
    });
  }

  if (IS_CUSTOM_QUERY && customSellers?.isFetched && !customSellers?.data?.hits?.length) {
    return null;
  }

  return (
    <Bookstores
      {...{
        ...component,
        data: {
          ...component.data,
          sellers: customSellers?.data?.hits?.length ?
            customSellers.data.hits.slice(0, 5).map(s => { return { sellerId: s.document.id }; }).filter(Boolean) :
            (component as PageComponent<BookstoresPageComponent>).data.sellers
        }
      } as PageComponent<BookstoresPageComponent>}
    />
  );
}

export default function PageComponents(props: PageComponentManagerProps) {
  const { state } = useGlobalState();
  const components = state.ui.data.adminPageEditing
    ? props.components
    : props.components.filter((component) => component.publish && component.display.web);

  function getPageComponent() {
    return components.map((component) => {
      switch (component.type) {
        case 'collection':
          return (
            <div
              className={classNames(styles.component, styles[component.type])}
              key={component.id}
            >
              <Collection
                {...(component as PageComponent<CollectionPageComponent>)}
              />
            </div>
          );
        case 'tags':
          return (
            <div
              className={classNames(styles.component, styles[component.type])}
              key={component.id}
            >
              <Tags {...(component as PageComponent<TagsPageComponent>)} />
            </div>
          );
        case 'callout':
          return (
            <div
              className={classNames(styles.component, styles[component.type])}
              key={component.id}
            >
              <PageComponentCallout
                {...(component as PageComponent<CalloutPageComponent>)}
              />
            </div>
          );
        case 'bookstores':
          return (
            <div
              className={classNames(styles.component, styles[component.type])}
              key={component.id}
            >
              <CustomBookstoreHandler component={component} />
            </div>
          );
        case 'text':
          return (
            <div
              className={classNames(styles.component, styles[component.type])}
              key={component.id}
            >
              <div className={styles[`col-${(component.data as TextPageComponent)?.cols || '4'}`]}>
                <TextContent
                  mainComponent={component as PageComponent<TextPageComponent>}
                  colData={component.data as TextPageComponent & { index?: number; }}
                />
              </div>
              {(component.data as TextPageComponent)?.additionalText?.map((additionalText, index) => (
                <div key={index} className={styles[`col-${additionalText?.cols || '1'}`]}>
                  <TextContent
                    mainComponent={component as PageComponent<TextPageComponent>}
                    colData={additionalText as TextPageComponent & { index?: number; }}
                  />
                </div>
              ))}
            </div>
          );
        case 'image':
          return (
            <div
              className={classNames(styles.component, styles[component.type])}
              key={component.id}
            >
              <ImageComponent
                {...(component as PageComponent<ImagePageComponent>)}
              />
            </div>
          );
        case 'fullTitle':
          return (
            <div
              className={classNames(styles.component, styles[component.type])}
              key={component.id}
            >
              <FullTitle
                {...(component as PageComponent<FullTitlePageComponent>)}
              />
            </div>
          );
      }
    });
  }

  return <section className={styles.container}>{getPageComponent()}</section>;
}
