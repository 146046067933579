
export default function FormatLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      height="1.5rem"
      width="1.5rem"
      {...props}
    >
      <path
        fill="currentColor"
        d="M4 5a1 1 0 000 2h16a1 1 0 100-2H4zM4 9a1 1 0 000 2h8a1 1 0 100-2H4zM3 14a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1zM4 17a1 1 0 100 2h8a1 1 0 100-2H4z"
      />
    </svg>
  );
}
