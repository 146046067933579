import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import classNames from 'classnames';
import { images, formatMoney } from 'sdk';

import { Book, UserProfile, BookstoresPageComponent, PageComponent } from 'types';

import Links from '~/lib/helpers/link-helper';

import FollowButton from '../follow-button';
import UserProfileImage from '../user-profile-image';
import UserReviewStars from '../user-review-stars';
import TileRow from '../tile-row';

import MoneyBackSVG from '~/assets/svg/icons/money-back';
import TruckSVG from '~/assets/svg/icons/truck';
import COLORS from '~/lib/helpers/color-helper';

import styles from '~/styles/components/page-components/bookstores.module.scss';
import { FetchCollectionDocsByQueryProps } from '~/clients/firebase/client';
import Firebase from '@/clients/firebase/firebase-react-query';
import { limit, orderBy, where } from 'firebase/firestore';

export default function Bookstores(props: PageComponent<BookstoresPageComponent>) {
  const queries: FetchCollectionDocsByQueryProps[] = [];
  props.data.sellers.forEach((seller) => {
    return queries.push({
      path: 'books',
      constraints: [
        where('seller_id', '==', seller.sellerId),
        orderBy('wishes', 'desc'),
        limit(3)
      ]
    });
  });

  const {
    data: sellers,
    isLoading: isLoadingSellers,
  } = Firebase<UserProfile>().fetchDataFromIds(
    props.data.sellers.map((seller) => seller.sellerId),
    'users',
    { enabled: Boolean(props.data.sellers?.length) }
  );

  const {
    data: books,
    isLoading: isLoadingBooks,
  } = Firebase<Book>().multiFetchCollectionDocsByQuery({
    queries,
    reactQueryOptions: { enabled: Boolean(queries?.length) }
  });

  function renderBookImages(books: Book[]) {
    const bookImages = books.map((book, index) => {
      return (
        <div
          key={book.id}
          className={classNames(styles['bookstores-book-image'], [
            { [styles['bookstores-book-image--secondary']]: index === 1 },
            { [styles['bookstores-book-image--tertiary']]: index === 2 },
          ])}
        >
          <Link href={Links.book(book.id).show} passHref>
            <Image
              src={images(book.photo_path).book.thumb}
              alt={book.title}
              width={300}
              height={300}
              unoptimized
              style={{
                maxWidth: '100%',
                height: 'auto'
              }}
            />
          </Link>
        </div>
      );
    });

    return <div className={styles['bookstores-image-container']}>{bookImages}</div>;
  }

  /**
   * Since we fetch books and seller info seperately, we find the matching seller for the book's seller id
   */
  function findSellerForBook(books: Book[]) {
    if (!books || !books[0]?.seller_id) return;

    const sellerId = books[0].seller_id;
    const seller = sellers.find((s) => s.id === sellerId);

    return seller;
  }

  function renderSellerInfo(seller: UserProfile) {
    return (
      <div className={styles['bookstores-seller']}>
        <div className={styles['bookstores-seller-profile-image']}>
          <UserProfileImage user={seller} />
        </div>
        <Link href={Links.bookstore(seller).show} passHref>

          <div className={styles['bookstores-seller-info']}>
            <h2>{seller?.username}</h2>
            <div className={styles['bookstores-seller-reviews']}>
              <UserReviewStars user={seller} withCountText />
            </div>
          </div>

        </Link>
        <FollowButton seller={seller} />
      </div>
    );
  }

  function renderSalesCallouts(seller: UserProfile) {
    if (!seller) return null;
    let { discounts } = seller;
    if (!discounts) return null;

    // limit length of discounts to 3
    discounts = discounts.slice(0, 3);

    return (
      <div className={styles['sales-callouts-container']}>
        {
          discounts.map((discount, i) => {
            function renderSalesCopy() {
              const minValueText = discount?.conditions?.minValue ?
                ` over ${formatMoney(discount.conditions.minValue)}` : '';

              if (discount.type === 'percent') {
                return <h3>{`${discount.additional ? 'Additional ' : ''}${discount.amount}% off${minValueText}`}</h3>;
              }
              else if (discount.type === 'value') {
                return <h3>{`${discount.additional ? 'Additional ' : ''}${formatMoney(discount.amount)} off${minValueText}`}</h3>;
              }
              else if (discount.type === 'shipping') {
                if (!discount?.conditions?.minValue) return null;
                return <h3 >{`Free Shipping${minValueText}`}</h3>;
              }
              return null;
            }

            return (
              <div key={`${discount?.type}-${discount.amount}-${seller.id}-${i}`} className={styles['sale-callout-container']}>
                <div>
                  {
                    discount.type === 'shipping' ?
                      <TruckSVG />
                      :
                      <MoneyBackSVG fillColor={COLORS.green} />
                  }
                </div>
                <div >
                  {renderSalesCopy()}
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }

  function renderBookstoreTile(books: Book[]) {
    if (!books?.length) return null;
    const seller = findSellerForBook(books);
    if (!seller) return null;

    return (
      <div className={styles['bookstores-tile']}>
        {renderBookImages(books)}
        {renderSalesCallouts(seller)}
        {renderSellerInfo(seller)}
      </div>
    );
  }

  function loaded() {
    return !isLoadingBooks && !isLoadingSellers && books?.length;
  }

  function renderBookstoreTiles() {
    if (!loaded() || !props.data.sellers.length) return null;

    return books.map((b) => {
      if (!b.length) return;
      return <div key={b[0].seller_id}>{renderBookstoreTile(b)}</div>;
    });
  }

  return (
    <TileRow title={props.data.customTitle ? props.data.customTitle : 'Sellers To Follow'} tileStyle="seller">
      {renderBookstoreTiles()}
    </TileRow>
  );
}
