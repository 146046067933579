import { PageComponent, TagsPageComponent } from 'types';
import Pill from '../pill';

import styles from '../../styles/components/page-components/tags.module.scss';
import { Links } from '~/lib/helpers/link-helper';
import { useRouter } from 'next/router';
import { mapQueryBy, mapSortBy } from 'sdk';

export default function Tags(props: PageComponent<TagsPageComponent>) {
  const router = useRouter();

  function tags() {
    return props.data.tags?.map((tag, idx) => (
      <Pill
        key={idx}
        text={tag}
        link={{
          href: Links.searchWithQuery({
            router,
            options: {
              index: 'books',
              params: {
                q: '*',
                sort_by: mapSortBy('books'),
                query_by: mapQueryBy('books'),
                filter_by: `hashtags:${tag}`
              },
              options: {}
            },
            path: '/search',
          }),
        }}
      />
    ));
  }

  return (
    <section>
      {props.data.title && (
        <h1 className={styles['tags-title']}>{props.data.title}</h1>
      )}
      <div className={styles['tags-links']}>{tags()}</div>
    </section>
  );
}
