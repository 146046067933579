import classNames from 'classnames';
import { PageComponent, TextPageComponent } from 'types';
import styles from '~/styles/components/page-components/text-content.module.scss';
import Image from 'next/image';
import RichText from '../rich-text';
import { useRouter } from 'next/router';
import { updatePageComponent } from '~/lib/helpers/page-component-helper';

interface Props {
  mainComponent: PageComponent<TextPageComponent>;
  colData: TextPageComponent & { index?: number; };
}
export default function TextContent({
  mainComponent,
  colData
}: Props) {
  const router = useRouter();

  async function handleSaveEditorState(_stringifiedEditorState: string) {
    let pagePath = router.asPath;
    if (pagePath.startsWith('/admin/page-editor')) pagePath = pagePath.replace('/admin/page-editor', '');
    if (pagePath.startsWith('/')) pagePath = pagePath.replace('/', '');

    const isSecondaryCol = colData?.index || colData?.index === 0;

    const newData: TextPageComponent = {
      ...colData,
      stringifiedEditorState: _stringifiedEditorState
    };

    const additionalText = mainComponent?.data?.additionalText || [];
    if (isSecondaryCol) {
      const index = additionalText.findIndex((text: any) => text.index === colData.index);
      if (index !== -1) {
        additionalText[index] = newData as any;
      }
    }

    await updatePageComponent(
      pagePath,
      {
        ...mainComponent,
        data: {
          ...mainComponent.data,
          ...(!isSecondaryCol && newData),
          additionalText
        }
      }
    );
    return;
  }

  function renderText() {
    return (
      <div className={styles['text-container']}>
        <RichText
          initialEditorState={colData.stringifiedEditorState}
          handleSave={handleSaveEditorState}
          editable={true}
        />
      </div>
    );
  }

  function renderMedia() {
    if (!colData.photo.url && !colData.youTubeId && !colData.tikTokId) return null;

    function renderImage() {
      return (
        <Image
          src={colData.photo.url}
          width={1000}
          height={1000}
          alt={colData.photo.alt || 'Photo'}
          unoptimized
          style={{
            maxWidth: '100%',
            height: 'auto'
          }}
        />
      );
    }

    function renderVideo() {
      const videoUrl = colData.youTubeId ? `https://www.youtube.com/embed/${colData.youTubeId}?controls=0` : `https://www.tiktok.com/embed/v3/${colData.tikTokId}`;
      return (
        <iframe
          src={videoUrl}
          title="Video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className={classNames(styles['video'], {
            [styles['video-tiny']]: colData.photo.size === 'tiny',
            [styles['video-tiny-4-3']]: colData.photo.crop === '4:3' && colData.photo.size === 'tiny',
            [styles['video-tiny-3-4']]: colData.photo.crop === '3:4' && colData.photo.size === 'tiny',
            [styles['video-small']]: colData.photo.size === 'small',
            [styles['video-small-4-3']]: colData.photo.crop === '4:3' && colData.photo.size === 'small',
            [styles['video-small-3-4']]: colData.photo.crop === '3:4' && colData.photo.size === 'small',
            [styles['video-base']]: colData.photo.size === 'base',
            [styles['video-base-4-3']]: colData.photo.crop === '4:3' && colData.photo.size === 'base',
            [styles['video-base-3-4']]: colData.photo.crop === '3:4' && colData.photo.size === 'base',
            [styles['video-large']]: colData.photo.size === 'large',
            [styles['video-large-4-3']]: colData.photo.crop === '4:3' && colData.photo.size === 'large',
            [styles['video-large-3-4']]: colData.photo.crop === '3:4' && colData.photo.size === 'large',
            [styles['video-xl']]: colData.photo.size === 'xl',
            [styles['video-xl-4-3']]: colData.photo.crop === '4:3' && colData.photo.size === 'xl',
            [styles['video-xl-3-4']]: colData.photo.crop === '3:4' && colData.photo.size === 'xl',
          })}
        />
      );
    }

    return (
      <div className={classNames(styles['photo'], {
        [styles['photo-tiny']]: colData.photo.size === 'tiny',
        [styles['photo-tiny-4-3']]: colData.photo.crop === '4:3' && colData.photo.size === 'tiny',
        [styles['photo-tiny-3-4']]: colData.photo.crop === '3:4' && colData.photo.size === 'tiny',
        [styles['photo-small']]: colData.photo.size === 'small',
        [styles['photo-small-4-3']]: colData.photo.crop === '4:3' && colData.photo.size === 'small',
        [styles['photo-small-3-4']]: colData.photo.crop === '3:4' && colData.photo.size === 'small',
        [styles['photo-base']]: colData.photo.size === 'base',
        [styles['photo-base-4-3']]: colData.photo.crop === '4:3' && colData.photo.size === 'base',
        [styles['photo-base-3-4']]: colData.photo.crop === '3:4' && colData.photo.size === 'base',
        [styles['photo-large']]: colData.photo.size === 'large',
        [styles['photo-large-4-3']]: colData.photo.crop === '4:3' && colData.photo.size === 'large',
        [styles['photo-large-3-4']]: colData.photo.crop === '3:4' && colData.photo.size === 'large',
        [styles['photo-xl']]: colData.photo.size === 'xl',
        [styles['photo-xl-4-3']]: colData.photo.crop === '4:3' && colData.photo.size === 'xl',
        [styles['photo-xl-3-4']]: colData.photo.crop === '3:4' && colData.photo.size === 'xl',
        [styles['photo-right']]: colData.photo.position === 'right',
        [styles['photo-primary-bg']]: colData.photo.bgColor === 'primary',
        [styles['photo-yellow-bg']]: colData.photo.bgColor === 'yellow',
        [styles['photo-orient-left']]: colData.photo.orient === 'left',
        [styles['photo-orient-right']]: colData.photo.orient === 'right',
        [styles['photo-no-background']]: Boolean(colData.photo.noBackground)
      })}>
        <div style={{ maxHeight: '75%', minHeight: '75%' }}>
          {colData.photo?.url ? renderImage() : renderVideo()}
        </div>
      </div >
    );
  }

  return (
    <div className={classNames(styles['text-content'], { [styles['row-reverse']]: colData.photo.position === 'right' })}>
      {renderMedia()}
      {renderText()}
    </div>
  );
}