
export default function FormatItalic(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      height="1.5rem"
      width="1.5rem"
      {...props}
    >
      <path
        fill="currentColor"
        d="M11.49 5.458h6l-.711 1.87h-2l-3.558 9.345h2l-.711 1.87h-6l.711-1.87h2l3.558-9.346h-2l.711-1.869z"
      />
    </svg>
  );
}