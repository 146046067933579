import { formatMoney, images, titlePhotoPath } from 'sdk';
import { Title } from 'types';
import LoadableImage from '@/components/loadable-image';
import { Box } from '@/components/layout/box';
import styles from '@/styles/components/tiles/full-width-title.module.scss';
import Text from '@/components/typography/text';
import Heading from '@/components/typography/heading';
import Links from '@/lib/helpers/link-helper';
import Link from 'next/link';
import TitleStarRating from '@/components/title-star-rating';
import { Flex } from '@/components/layout/flex';
import Summary from './summary';
import AddToCartButton from '@/components/product/add-to-cart-button';
import Tooltip from '@/components/tooltip';
import InfoTruckSVG from '@/assets/svg/icons/info-truck';
import InfoMoneySVG from '@/assets/svg/icons/info-money';
import ShelfButton from './shelf-button';

export interface FullWidthTitleProps {
  title: Title;
  bookOrder?: string | number;
  publishYear?: string | number;
  topContent?: React.ReactNode;
}
export default function FullWidthTitle({
  title,
  bookOrder,
  publishYear,
  topContent,
}: FullWidthTitleProps) {

  function renderBookOrder() {
    if (topContent) return topContent;
    if (!bookOrder && !publishYear) return null;

    return (
      <Text mb={0.5}>
        {bookOrder && <Text size='small' color='copy' as='span'>Book {bookOrder}</Text>}
        {(bookOrder && publishYear) && <Text size='small' color='copy' as='span'> • </Text>}
        {publishYear && <Text size='small' color='copy' as='span'>Published {publishYear}</Text>}
      </Text>
    );
  }

  function renderImage() {
    return (
      <>
        <Link href={Links.title(title).show.pathname}>
          <LoadableImage
            src={images(titlePhotoPath(title))?.book?.tile}
            alt={title.title}
            fill
          />
        </Link>
        <Box className={styles.shelf}>
          <ShelfButton title={title} source='tile' />
        </Box>
      </>
    );
  }

  function renderTitle() {
    return (
      <Link href={Links.title(title).show.pathname}>
        <Heading mb={0.5} size='medium' as='h3'>{title.title}</Heading>
      </Link>
    );
  }

  function renderAuthor() {
    return (
      <Text mb={0.5}>
        <Text as='span'>by </Text>
        <Link href={Links.author.slug({ slug: title?.authors?.[0]?.id }).pathname}>
          <Text as='span' link>{title?.authors?.[0]?.name}</Text>
        </Link>
      </Text>
    );
  }

  function renderRating() {
    return (
      <Flex>
        <TitleStarRating
          type='full'
          title={title}
          noSpacer
        />
      </Flex>
    );
  }

  function renderSummary() {
    return <Summary title={title} />;
  }

  function renderPrice() {
    const hasPriceDrop = title?.selected_listing?.price_drop?.active;
    const priceDropPrevAmount = title?.selected_listing?.price_drop?.previous_amount;

    if (!title.selected_listing) return (
      <Box mb={1}>
        <Text>Sold out</Text>
      </Box>
    );

    return (
      <Box className={styles.price}>
        {hasPriceDrop &&
          <Text size='tiny' mb={0.5} color='green'>Price drop</Text>
        }
        <Text>
          <Text font='bold' as='span' size='large'>{formatMoney(title.selected_listing?.amount)} </Text>
          {hasPriceDrop &&
            <Text as='span' strikeThru color='copy'> {formatMoney(priceDropPrevAmount)}</Text>
          }
        </Text>
      </Box>
    );
  }

  function renderSales() {
    const showShipping = title?.selected_listing?.discounts?.find(d => d.type === 'shipping');
    const showSales = title?.selected_listing?.discounts?.find(d => d.type === 'percent') || title?.selected_listing?.discounts?.find(d => d.type === 'value');

    if (!showShipping && !showSales) return null;

    return (
      <Box className={styles.sales}>
        {showShipping &&
          <Flex direction='row' align='center'>
            <Box mr={0.25}>
              <Tooltip
                content='This seller is offering free shipping on qualifying orders. Visit their shop for details.'
                position='left'
              >
                <Box>
                  <InfoTruckSVG size={18} />
                </Box>
              </Tooltip>
            </Box>
            <Text size='tiny'>Free shipping eligible</Text>
          </Flex>
        }
        {showSales &&
          <Flex direction='row' align='center'>
            <Box mr={0.25}>
              <Tooltip
                content='This seller is offering free shipping on qualifying orders. Visit their shop for details.'
                position='left'
              >
                <Box>
                  <InfoMoneySVG size={18} />
                </Box>
              </Tooltip>
            </Box>
            <Text size='tiny'>Seller is having sales</Text>
          </Flex>
        }
      </Box>
    );
  }

  function renderAddToCart() {
    return (
      <Box className={styles.cart}>
        <AddToCartButton
          book={title.selected_listing}
          source='tile'
          selectedListing
          style='secondary'
          disabled={!title.selected_listing}
        />
      </Box>
    );
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.image}>
        {renderImage()}
      </Box>
      <Box className={styles.mainInfo}>
        {renderBookOrder()}
        {renderTitle()}
        {renderAuthor()}
        {renderRating()}
      </Box>
      <Box className={styles.summary}>
        {renderSummary()}
      </Box>
      <Box className={styles.pricingInfo}>
        {renderPrice()}
        {renderSales()}
        {renderAddToCart()}
      </Box>
    </Box>
  );
}

