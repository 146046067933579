/* eslint-disable @next/next/no-img-element */
import Link from 'next/link';
import { ImagePageComponent, PageComponent } from 'types';
import styles from '~/styles/components/page-components/image.module.scss';

export default function ImageComponent(props: PageComponent<ImagePageComponent>) {

  function renderImage() {
    const image = (
      <img
        src={props.data.photoUrl}
        alt={props.data.altText}
      />
    );
    if (props.data.href) {
      return (
        <Link href={props.data.href} target='_blank'>
          {image}
        </Link>
      );
    }
    return image;
  }

  function renderCaption() {
    if (!props.data.caption) return null;

    return (
      <p className={styles['caption']}>{props.data.caption}</p>
    );
  }

  return (
    <div className={styles['container']} style={{ width: props.data.width }}>
      {renderImage()}
      {renderCaption()}
    </div>
  );
}