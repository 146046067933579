
export default function FormatCenter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      height="1.5rem"
      width="1.5rem"
      {...props}
    >
      <path
        fill="currentColor"
        d="M4 5a1 1 0 000 2h16a1 1 0 100-2H4zM4 13a1 1 0 100 2h16a1 1 0 100-2H4zM6 10a1 1 0 011-1h10a1 1 0 110 2H7a1 1 0 01-1-1zM7 17a1 1 0 100 2h10a1 1 0 100-2H7z"
      />
    </svg>
  );
}