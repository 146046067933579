'use client';

import { ProductViewSource, Title } from 'types';
import useShelves from '@/lib/hooks/use-shelves';
import Tooltip from '@/components/tooltip';
import SessionForm from '@/components/session-form';
import useAuthenticatedAction from '@/lib/hooks/use-authenticated-action';
import WishEmptySVG from '@/assets/svg/icons/wish-empty';
import WishFilledSVG from '@/assets/svg/icons/wish-filled-new';
import { Box } from '@/components/layout/box';

interface ShelfButtonProps {
  title: Title;
  source: ProductViewSource;
}
export default function ShelfButton({ title, source }: ShelfButtonProps) {

  const { handleShelf, isTitleInShelf } = useShelves({ book: title.selected_listing, source });

  const { handleAuthAction } = useAuthenticatedAction({
    actionToCall: () => handleShelf({ title, type: 'title' }),
  });

  function handleShelfClick() {
    handleAuthAction({
      title: 'Login to add to Your Shelves',
      message: "You need an account to add this to your shelves, please sign in with one of the methods below.",
      component: <SessionForm />,
    });
  }

  return (
    <Tooltip
      content='Add this to a shelf'
      position='left'
    >
      <Box onClick={handleShelfClick} className=''>
        {isTitleInShelf(title.id) ? <WishFilledSVG /> : <WishEmptySVG />}
      </Box>
    </Tooltip>
  );
}