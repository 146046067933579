import { CalloutPageComponent, PageComponent } from 'types';

import Callout from '../callout';

const defaultProps: CalloutPageComponent = {
  style: 'primary',
};

export default function PageComponentCallout(
  props: PageComponent<CalloutPageComponent>
) {
  let data = { ...defaultProps, ...props.data };

  return <Callout {...data} />;
}
