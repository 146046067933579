
export default function FormatUnderline(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      height="1.5rem"
      width="1.5rem"
      {...props}
    >
      <path
        fill="currentColor"
        d="M6 10V4h2v6a4 4 0 008 0V4h2v6a6 6 0 01-12 0zM7 18a1 1 0 100 2h10a1 1 0 100-2H7z"
      />
    </svg>
  );
}