
export default function FormatParagraph(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1.5rem"
      width="1.5rem"
      {...props}
    >
      <path d="M13 4a4 4 0 014 4 4 4 0 01-4 4h-2v6H9V4h4m0 6a2 2 0 002-2 2 2 0 00-2-2h-2v4h2z" />
    </svg>
  );
}
