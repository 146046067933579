import {
  createFirestoreDoc,
  deleteFirestoreDoc,
  fetchDocByRef,
  updateFirestoreDoc,
} from '../../clients/firebase/client';

import { Page, PageComponent, PageComponentData, TextPageComponent } from 'types';

export function readablePageComponentType(component: PageComponent<PageComponentData>) {
  switch (component.type) {
    case 'collection':
      return 'Collection';
    case 'tags':
      return 'Tags';
    case 'bookstores':
      return 'Bookstores';
    case 'callout':
      return 'Callout';
    case 'text':
      return 'Text';
    case 'image':
      return 'Image';
    case 'fullTitle':
      return 'Full width title';
    default:
      throw new Error(`No component type found for ${component.type}`);
  }
}

const defaultBasePageComponent = {
  index: 0,
  loaded: false,
  ssr: false,
  publish: false,
  id: null,
  display: {
    web: false,
    native: false,
  },
};

export const defaultTextData: TextPageComponent = {
  photo: {
    url: '',
    position: 'left',
    alt: '',
    size: 'base',
    noBackground: false,
    crop: '1:1',
  },
  youTubeId: '',
  tikTokId: '',
  stringifiedEditorState: '',
};

export const defaultPageComponents: {
  [c: string]: PageComponent<PageComponentData>;
} = {
  tags: {
    ...defaultBasePageComponent,
    type: 'tags',
    data: {
      title: 'Example Title',
      tags: ['example-tag'],
    },
  },
  callout: {
    ...defaultBasePageComponent,
    type: 'callout',
    data: {
      style: 'primary',
      title: 'Example Title',
      message: 'Example message',
    },
  },
  collection: {
    ...defaultBasePageComponent,
    type: 'collection',
    data: {
      title: 'Example Title',
      description: null,
      title_ids: [],
      titles: [],
      book_ids: [],
      books: [],
      queryResults: null,
      query: null,
      featured: false,
      featuredPhoto: null,
    },
  },
  bookstores: {
    ...defaultBasePageComponent,
    type: 'bookstores',
    data: {
      sellers: [],
    },
  },
  text: {
    ...defaultBasePageComponent,
    type: 'text',
    data: {
      ...defaultTextData,
      additionalText: [],
    },
  },
  image: {
    ...defaultBasePageComponent,
    type: 'image',
    data: {
      photoUrl: '',
      href: '',
      caption: '',
      width: '50%',
      altText: '',
    },
  },
  fullTitle: {
    ...defaultBasePageComponent,
    type: 'fullTitle',
    data: {
      titleId: ''
    },
  },
};

export async function createPage(pagePath: string) {
  // insert the pages subcollection if it's a page deeper than the root
  if (pagePath.includes('/')) pagePath = pagePath.replace(/\//g, '/pages/');
  const pageName = pagePath.split('/').pop();
  pagePath = pagePath.replace(`/${pageName}`, '');
  const docRef = await createFirestoreDoc(
    `pages${pagePath === pageName ? '' : `/${pagePath}`}`,
    {
      meta: {
        title: '',
        description: '',
      },
      name: pagePath.split('/').pop(),
      updated: new Date(),
    },
    pageName
  );

  return fetchDocByRef(docRef);
}

export async function createPageComponent(pagePath: string, component: PageComponent<PageComponentData>) {
  // The default page component passed to this from the editor includes a null ID for type checking.
  // We remove the ID before saving it to the server, and the return value of this includes the actual ID
  if (pagePath.includes('/')) pagePath = pagePath.replace(/\//g, '/pages/');
  delete component.id;
  const docRef = await createFirestoreDoc(`pages/${pagePath}/components`, component);

  return fetchDocByRef(docRef);
}

export async function updatePageComponent(pagePath: string, component: PageComponent<PageComponentData>) {
  if (pagePath.includes('/')) pagePath = pagePath.replace(/\//g, '/pages/');
  const docRef = await updateFirestoreDoc(`pages/${pagePath}/components`, component.id, component);

  const pageId = pagePath.split('/').pop();
  pagePath = pagePath.replace(`${pageId}`, '');
  await updateFirestoreDoc(`pages/${pagePath}`, pageId, { updated: new Date() });

  return fetchDocByRef(docRef);
}

export async function deletePageComponent(pagePath: string, component: PageComponent<PageComponentData>) {
  if (pagePath.includes('/')) pagePath = pagePath.replace(/\//g, '/pages/');
  return deleteFirestoreDoc(`pages/${pagePath}/components`, component.id);
}

export async function updatePageHelper(page: Page, pagePath: string) {
  if (pagePath.includes('/')) pagePath = pagePath.replace(/\//g, '/pages/');
  const pageName = pagePath.split('/').pop();
  pagePath = pagePath.replace(`/${pageName}`, '');
  const docRef = await updateFirestoreDoc(`pages${pagePath === pageName ? '' : `/${pagePath}`}`, pageName, {
    ...page,
    published: Boolean(page.published),
    updated: new Date(),
  });
  return fetchDocByRef<Page>(docRef);
}
